import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import Slider from 'react-slick'
import Modal from 'react-modal'

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  content : {
    bottom: 'unset',
    maxWidth: '1000px',
    maxHeight: '700px',
    margin: '0 auto',
  }
}

class GalleryPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentSlide: 0,
      modalIsOpen: false,
    }
  }

  openModal = e => {
    this.setState({
      currentSlide: e,
      modalIsOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  render() {
    const currentPage = this.props.data.wordpressPage
    const pageClass = currentPage.title.replace(/\s+/g, '-').toLowerCase()
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false,
      initialSlide: this.state.currentSlide
    }

    return (
      <Layout>
        <SEO title={currentPage.title} />
        <div className="container">
          <div className="row">
            <div className="span12">
              <section className="title-section">
                <h1 className="title-header" dangerouslySetInnerHTML={{ __html: currentPage.title }} />
              </section>
              <div className={`page-${pageClass}`}>
                <ul className="row-fluid unstyled">
                {
                  currentPage.acf.images.map((item, i) => (
                    <li key={i} className="span4">
                      <figure className="featured-thumbnail thumbnail">
                        <a onClick={() => {this.openModal(i)}}>
                          <Img fluid={item.image.thumb.childImageSharp.fluid} title={item.caption} alt={item.caption} />
                          <span className="zoom-icon"></span>
                        </a>
                      </figure>
                    </li>
                  ))
                }
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Modal
            isOpen={this.state.modalIsOpen}
            contentLabel="Gallery image"
            style={customModalStyles}
        >
          <div className="modal-wrapper">
            <div className="bm-cross-button">
              <span className="cross-container">
                  <span className="bm-cross left"></span>
                  <span className="bm-cross right"></span>
              </span>
              <button onClick={this.closeModal}>Close Menu</button>
            </div>
            <div className="modal-contents gallery">
              <Slider ref="slider" {...sliderSettings}>
                {
                  currentPage.acf.images.map((item, i) => (
                      <div key={i}>
                        <Img fluid={item.image.fullsize.childImageSharp.fluid} title={item.caption} alt={item.caption} />
                      </div>
                  ))
                }
              </Slider>
            </div>
          </div>
        </Modal>

      </Layout>
    )
  }
}

export default GalleryPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "gallery" }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
      acf {
        images {
          image {
            thumb: localFile {
              childImageSharp {
                fluid(maxWidth: 368) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            fullsize: localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          caption
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
